import { BaseWidget } from "./BaseWidget";
import { BsCurrencyDollar } from "react-icons/bs";

interface Props {
  value: number;
  compareValue: number;
  loading: boolean;
  currency: string;
}

export const RevenueWidget = ({
  value,
  compareValue,
  loading,
  currency,
}: Props) => {
  return (
    <BaseWidget
      title={"Revenue"}
      value={value}
      compareValue={compareValue}
      loading={loading}
      currency={currency}
      downIsGood={false}
      subtitle="Total revenue from all sales"
      icon={<BsCurrencyDollar color="white" />}
    />
  );
};
