/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// @mui material components

import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Footer from "examples/Footer";
// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// icons
import { HeaderWidgets } from "./HeaderWidgets";
import { useSelector } from "react-redux";
import Loading from "components/shared-components/Loading";
import RevenueChart from "components/shared-components/RevenueChart";
import { COLORS } from "constants/ChartConstant";
import { TopVariantsChart } from "views/app-views/dashboards/sales/TopVariantsChart";
import { CustomerWidgets } from "views/app-views/dashboards/sales/CustomersWidgets";
import CustomersChart from "components/shared-components/RevenueChart/CustomersChart";
import AOVChart from "components/shared-components/RevenueChart/AOVChart";
import WarningMessage from "../warning";

const sessionColor = [
  COLORS[0],
  COLORS[1],
  COLORS[2],
  COLORS[3],
  COLORS[4],
  COLORS[5],
];

function Default() {
  const { values } = breakpoints;
  const selectedMer = useSelector(
    (state: any) => state.merchants.selectedMerchant
  );

  const facebookData = useSelector((state: any) => state.facebookData.data);
  const googleData = useSelector((state: any) => state.googleData.data);
  const filteredOrders = useSelector(
    (state: any) => state.shopifyOrders.filteredOrders
  );
  const {
    averageAmount,
    graphData,
    graphDataRefunds,
    newCustomersGraphData,
    returningCustomersGraphData,
  } = filteredOrders;
  const compareData = useSelector(
    (state: any) => state.shopifyOrders.compareData
  );
  let seriesByCount: any[] = [];
  let seriesByRevenue: any[] = [];
  let labels: any[] = [];
  let index =
    filteredOrders.products && filteredOrders.products.length
      ? filteredOrders.products.length > 5
        ? 5
        : filteredOrders.products.length
      : 0;
  let combinedData: any[] = [];

  for (let i = 0; i < index; i++) {
    const product = filteredOrders.products[i];
    seriesByCount.push(product.count);
    seriesByRevenue.push(Number(product.totalPrice));
    labels.push(filteredOrders.products[i].displayTitle || "No Product");
    const count = filteredOrders.products[i].count;
    const price = filteredOrders.products[i].totalPrice;
    const label = filteredOrders.products[i].displayTitle;
    const color = sessionColor[i];
    combinedData = [
      ...combinedData,
      {
        count: count,
        price: price,
        label: label,
        color: color,
      },
    ];
  }
  if (
    filteredOrders.products &&
    filteredOrders.products.length &&
    filteredOrders.products.length > 5
  ) {
    let tempCount = 0;
    let tempPrice = 0;
    for (let i = index; i < filteredOrders.products.length; i++) {
      tempCount += filteredOrders.products[i].count;
      tempPrice += Number(filteredOrders.products[i].totalPrice);
    }
    labels.push("Others");
    seriesByCount.push(tempCount);
    seriesByRevenue.push(tempPrice);
  }

  if (!selectedMer?.id) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container mt={10} justifyContent="center">
          <Grid item xs={12} md={6}>
            <WarningMessage
              text="No merchant account setup"
              detail="Please add a merchant account to view your dashboard"
              url="app/settings/add-account"
              actionText="Add Account"
            />
          </Grid>
        </Grid>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Grid container>
          <Grid item xs={12}>
            <VuiBox mb={3} p={1}>
              <VuiTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
                color="white"
              >
                Your Dashboard
              </VuiTypography>
            </VuiBox>

            <Grid container spacing={3}>
              <HeaderWidgets />
            </Grid>
          </Grid>
          <Grid item xs={12} mb={3}>
            {Object.keys(filteredOrders).length === 0 ? (
              <Loading />
            ) : (
              <RevenueChart
                shopifyGraphData={graphData}
                facebookGraphData={facebookData?.graphData || []}
                googleGraphData={googleData?.graphData || []}
                shopifyRefunds={graphDataRefunds}
                compareData={compareData}
              />
            )}
          </Grid>
          <Grid item xs={12} mb={3}>
            <CustomerWidgets facebookROAS={facebookData.roas || 0} />
          </Grid>
          <Grid item xs={12} mb={3}>
            <TopVariantsChart />
          </Grid>
          <Grid item xs={12} mb={3}>
            {!newCustomersGraphData ? (
              <Loading />
            ) : (
              <CustomersChart
                newCustomersGraphData={newCustomersGraphData}
                returningCustomersGraphData={returningCustomersGraphData}
              />
            )}
          </Grid>
          <Grid item xs={12} mb={3}>
            {!newCustomersGraphData ? (
              <Loading />
            ) : (
              <AOVChart
                AOVGraphData={graphData}
                newCustomersGraphData={newCustomersGraphData}
                returningCustomersGraphData={returningCustomersGraphData}
                totalAOV={averageAmount}
              />
            )}
          </Grid>
        </Grid>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
