import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import VuiBox from "components/VuiBox";
import { Card } from "@mui/material";
import VuiTypography from "components/VuiTypography";

const chartOptions = {
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "70%",
      barHeight: "100%",
      distributed: false,
      rangeBarOverlap: true,
      rangeBarGroupRows: false,
      // borderRadiusApplication: "end",
      // borderRadius: 6,
      dataLabels: {
        enabled: false,
      },
    },
  },
  fill: {},
  stroke: {
    curve: "smooth",
    width: 2,
  },
};

const RevenueChart = ({
  shopifyGraphData,
  shopifyRefunds,
  facebookGraphData,
  googleGraphData,
  compareData,
}) => {
  const { plotOptions, fill } = chartOptions;
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const selectedMerchant = useSelector(
    (state) => state.merchants.selectedMerchant
  );

  const monthNames = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  useEffect(() => {
    const normalizeAndCombineData = () => {
      if (!shopifyGraphData || !facebookGraphData || !googleGraphData) {
        return [];
      }

      const allDates = new Set([
        ...shopifyGraphData.map((d) => d._id),
        ...facebookGraphData.map((d) => d._id),
        ...googleGraphData.map((d) => d._id),
        ...(compareData?.map((d) => d._id) || []),
      ]);

      const sortedDates = Array.from(allDates).sort();

      return sortedDates.map((date) => {
        const shopify = shopifyGraphData.find((d) => d._id === date) || {
          totalAmount: 0,
        };
        const facebook = facebookGraphData.find((d) => d._id === date) || {
          totalSpend: 0,
        };
        const google = googleGraphData.find((d) => d._id === date) || {
          cost: 0,
        };
        const compare = compareData?.find((d) => d._id === date) || {
          revenue: 0,
          spend: 0,
        };

        return {
          _id: date,
          shopifyRevenue: shopify.totalAmount - getRefundAmount(date),
          facebookSpend: facebook.totalSpend,
          googleCost: google.cost,
          compareRevenue: compare.revenue,
          compareSpend: compare.spend,
        };
      });
    };

    const combinedData = normalizeAndCombineData();

    if (combinedData.length === 0) {
      setOptions({});
      setSeries([]);
      return;
    }

    const shopifyData = combinedData.map((d) =>
      Number(d.shopifyRevenue.toFixed(2))
    );
    const facebookData = combinedData.map((d) =>
      Number(d.facebookSpend.toFixed(2))
    );
    const googleData = combinedData.map((d) => Number(d.googleCost.toFixed(2)));
    const compareDataValues = combinedData.map((d) =>
      Number(d.compareRevenue.toFixed(2))
    );
    const compareSpendValues = combinedData.map((d) =>
      Number(d.compareSpend.toFixed(2))
    );

    const maxDataValue = Math.max(
      ...shopifyData,
      ...facebookData,
      ...googleData,
      ...compareDataValues,
      ...compareSpendValues
    );
    const maxY = Math.ceil(maxDataValue / 2000) * 2000;

    const options = {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        stacked: true,
        type: "line",
      },
      xaxis: {
        categories: combinedData.map((value) => {
          const parts = value._id.toString().split("-");
          if (parts.length === 3) {
            return `${monthNames[parts[1]]} ${parts[2]}`;
          } else if (parts.length === 2) {
            return `${monthNames[parts[1]]} ${parts[0]}`;
          } else {
            return value._id.toString();
          }
        }),
        labels: {
          style: {
            colors: "#c8cfca",
            fontSize: "10px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: [
        {
          labels: {
            style: {
              colors: "#c8cfca",
              fontSize: "10px",
            },
            formatter: function (value) {
              return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: selectedMerchant.currency || "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(value);
            },
          },
          min: 0,
          max: maxY,
          title: {
            text: "Revenue",
          },
        },
        {
          opposite: true,
          axisBorder: {
            show: false,
          },
          labels: {
            show: false,
          },
          min: 0,
          max: maxY,
        },
      ],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        ...plotOptions,
        bar: {
          ...plotOptions.bar,
          stacked: true,
        },
      },
      fill,
      stroke: {
        width: [0, 0, 0, 2],
        curve: "straight",
        dashArray: [0, 0, 0, 5],
      },
      colors: ["#2CD9FF", "#0075FF", "#E6E6B3", "#2CD9FF", "#FF6B6B"],
      legend: {
        position: "top",
        horizontalAlign: "left",
        labels: {
          colors: "#c8cfca",
        },
      },
      tooltip: {
        theme: "dark",
        shared: true,
        intersect: false,
        y: {
          formatter: function (value) {
            return new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: selectedMerchant.currency || "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(value);
          },
        },
      },
      grid: {
        strokeDashArray: 5,
        borderColor: "#56577A",
      },
      markers: {
        size: [0, 0, 0, 4],
      },
    };

    setOptions(options);

    const series = [
      {
        name: "Shopify Revenue",
        type: "column",
        data: shopifyData,
      },
      {
        name: "Facebook Spend",
        type: "column",
        data: facebookData,
      },
      {
        name: "Google Cost",
        type: "column",
        data: googleData,
      },
      ...(compareData && compareData.length > 0
        ? [
            {
              name: "Target Revenue",
              type: "line",
              data: compareDataValues,
              stroke: {
                width: 2,
                curve: "straight",
                dashArray: 5,
              },
              markers: {
                size: 0,
              },
            },
            {
              name: "Target Spend",
              type: "line",
              data: compareSpendValues,
              stroke: {
                width: 2,
                curve: "straight",
                dashArray: 5,
              },
              markers: {
                size: 0,
              },
            },
          ]
        : []),
    ];

    setSeries(series);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    facebookGraphData,
    shopifyGraphData,
    googleGraphData,
    compareData,
    selectedMerchant.currency,
  ]);

  const getRefundAmount = (id) => {
    let refundAmount = 0;
    shopifyRefunds.forEach((value) => {
      if (value._id === id) {
        refundAmount = value.totalAmount;
      }
    });
    return Number(refundAmount.toFixed(2));
  };

  return (
    <Card>
      <VuiBox sx={{ height: "100%" }}>
        <VuiTypography
          variant="lg"
          color="white"
          fontWeight="bold"
          mb="5px"
          ml="10px"
        >
          Sales Overview
        </VuiTypography>
        <VuiBox sx={{ height: "400px" }}>
          {options && series && series.length > 0 && (
            <Chart
              options={options}
              series={series}
              width="100%"
              height="400"
            />
          )}
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default RevenueChart;
