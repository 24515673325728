import axios from "../interceptors";

const ShopifyOrderService = {};

ShopifyOrderService.filterByDate = async (
  merchantId,
  start,
  end,
  previousStart,
  previousEnd,
  datePreset = "",
  groupByMonth = ""
) => {
  return axios.get(
    datePreset
      ? `shopify/getOrders/today?merchantId=${merchantId}&start=${start}${
          end ? "&end=" + end : ""
        }${previousStart ? "&previousStart=" + previousStart : ""}${
          previousEnd ? "&previousEnd=" + previousEnd : ""
        }`
      : `shopify/getOrders?merchantId=${merchantId}&start=${start}${
          end ? "&end=" + end : ""
        }${previousStart ? "&previousStart=" + previousStart : ""}${
          previousEnd ? "&previousEnd=" + previousEnd : ""
        }${groupByMonth ? "&groupByMonth=" + groupByMonth : ""}`
  );
};

ShopifyOrderService.filterRefundsByDate = async (
  merchantId,
  start,
  end,
  previousStart,
  previousEnd,
  datePreset = "",
  groupByMonth = ""
) => {
  return axios.get(
    `shopify/getRefunds?merchantId=${merchantId}&start=${start}${
      end ? "&end=" + end : ""
    }${previousStart ? "&previousStart=" + previousStart : ""}${
      previousEnd ? "&previousEnd=" + previousEnd : ""
    }${groupByMonth ? "&groupByMonth=" + groupByMonth : ""}`
  );
};

ShopifyOrderService.getCostPerProduct = async (
  merchantId,
  start,
  end,
  token
) => {
  return axios.get(
    `shopify/getProductCosts?merchantId=${merchantId}&start=${start}${
      end ? "&end=" + end : ""
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

ShopifyOrderService.editCost = async (
  merchantId,
  title,
  variant,
  sku,
  cost,
  token
) => {
  return axios.put(
    `shopify/editCost`,
    {
      merchantId,
      title,
      variant,
      sku,
      cost,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

ShopifyOrderService.getProgress = async (merchantId, token) =>
  axios.get(`shopify/progress?merchantId=${merchantId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

ShopifyOrderService.getChannels = async ({ token, merchantId }) => {
  const url = `shopify/salesChannels?merchantId=${merchantId}`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export default ShopifyOrderService;
