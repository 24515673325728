type CompareOption = "time" | "targets";

interface GetSummaryProps {
  merchantId: string;
  start: string;
  end: string;
  compareOption?: CompareOption;
}

interface SummaryValue {
  value: number;
  compareValue: number;
}

export interface CustomerSummary {
  orderCount: SummaryValue;
  totalAmount: SummaryValue;
  customerCount: SummaryValue;
}

export interface CustomerSummaryData {
  newCustomers: CustomerSummary;
  returningCustomers: CustomerSummary;
  facebookROAS: SummaryValue;
  aMER: SummaryValue;
  totalOrders: SummaryValue;
  CAC: SummaryValue;
}

type SalesSummaryResponse = {
  data: CustomerSummaryData;
};

type GetFunction = <T>(url: string) => Promise<T>;

export class CustomersService {
  private get: GetFunction;

  constructor(getFn: GetFunction) {
    this.get = getFn;
  }

  async getSummary(params: GetSummaryProps): Promise<CustomerSummaryData> {
    const { merchantId, start, end, compareOption = "time" } = params;
    const response = await this.get<SalesSummaryResponse>(
      `customers/summary?merchantId=${merchantId}&start=${start}&end=${end}&compareOption=${compareOption}`
    );
    return response.data;
  }
}
