/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

/** 
  All of the routes for the Vision UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location. 
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Vision UI Dashboard PRO React layouts
import Default from "layouts/dashboards/default";
import Targets from "layouts/targets";
import CostsAndExpenses from "layouts/costsAndExpenses";
import StoreDetails from "layouts/settings/storeDetails";
import StoreConnections from "layouts/settings/connectPage";
import ShopifyCallback from "layouts/settings/shopifyCallback";

// React icons
import { IoSettings } from "react-icons/io5";
import { FaShoppingCart, FaChartLine } from "react-icons/fa";
import { IoHome } from "react-icons/io5";

const routes = [
  {
    type: "collapse",
    name: "Sales Dashboard",
    key: "dashboard",
    icon: <IoHome size="15px" color="inherit" />,
    noCollapse: true,
    layout: "/admin",
    path: "/app",
    component: <Default />,
  },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Targets",
    key: "targets",
    icon: <FaChartLine size="15px" color="inherit" />,
    noCollapse: true,
    layout: "/admin",
    path: "/app/targets",
    component: <Targets />,
  },
  {
    type: "collapse",
    name: "Costs & Expenses",
    key: "costs-expenses",
    icon: <FaShoppingCart size="15px" color="inherit" />,
    noCollapse: true,
    layout: "/admin",
    path: "/app/costs-expenses",
    component: <CostsAndExpenses />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <IoSettings size="15px" color="inherit" />,
    collapse: [
      {
        name: "Store Connections",
        key: "store-connections",
        layout: "/admin",
        path: "/app/settings/store-connections",
        component: <StoreDetails />,
      },
      {
        name: "Add account",
        key: "add-account",
        layout: "/admin",
        path: "/app/settings/add-account",
        component: <StoreConnections />,
      },
    ],
  },
  {
    name: "Hidden Page",
    key: "hidden-page",
    path: "/app/dashboards/merchant/shopifyCallback",
    layout: "/admin",
    component: <ShopifyCallback />,
  },
];

export default routes;
