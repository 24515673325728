import { FC } from "react";
import { Avatar } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";

interface AvatarStatusProps {
  name?: string;
  suffix?: string;
  subTitle?: string;
  id?: string | number;
  type?: string;
  src?: string;
  icon?: React.ReactNode;
  size?: "small" | "medium" | "large";
  shape?: "circular" | "rounded" | "square";
  gap?: number;
  text?: string;
  onNameClick?: (data: {
    name?: string;
    subTitle?: string;
    src?: string;
    id?: string | number;
  }) => void;
  cardType?: string;
}

export const AvatarStatus: FC<AvatarStatusProps> = ({
  name,
  suffix,
  subTitle,
  id,
  type,
  src,
  icon,
  size = "medium",
  shape = "circular",
  gap,
  text,
  onNameClick,
  cardType,
}) => {
  const renderAvatar = () => (
    <Avatar
      src={src}
      sx={{
        bgcolor: type ? `${type}.main` : undefined,
        width: size === "small" ? 24 : size === "large" ? 48 : 36,
        height: size === "small" ? 24 : size === "large" ? 48 : 36,
        borderRadius:
          shape === "rounded" ? "10%" : shape === "square" ? "0" : "50%",
      }}
    >
      {icon || text}
    </Avatar>
  );

  return (
    <VuiBox display="flex" alignItems="center">
      {cardType === "Top Products" ? (
        <VuiTypography variant="button" color="text">
          {src + "."}
        </VuiTypography>
      ) : (
        renderAvatar()
      )}

      <VuiBox ml={2}>
        <VuiBox>
          {onNameClick ? (
            <VuiTypography
              variant="button"
              color="white"
              fontWeight="medium"
              sx={{ cursor: "pointer" }}
              onClick={() => onNameClick({ name, subTitle, src, id })}
            >
              {name}
            </VuiTypography>
          ) : (
            <VuiTypography
              variant="button"
              color="white"
              fontWeight="medium"
              sx={{
                whiteSpace: "nowrap",
                width: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                "&:hover": { overflow: "visible" },
              }}
            >
              {name}
            </VuiTypography>
          )}
          {suffix && (
            <VuiTypography
              variant="button"
              color="text"
              fontWeight="regular"
              ml={1}
            >
              {suffix}
            </VuiTypography>
          )}
        </VuiBox>
        {subTitle && (
          <VuiTypography variant="caption" color="text">
            {subTitle}
          </VuiTypography>
        )}
      </VuiBox>
    </VuiBox>
  );
};

export default AvatarStatus;
