import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

interface Props {
  value: number;
}

export const FacebookROAS = ({ value }: Props) => {
  return (
    <MiniStatisticsCard
      title={{ text: "Facebook ROAS", fontWeight: "bold" }}
      count={value.toFixed(2)}
    />
  );
};
