import React from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";

interface Props {
  defaultValue: string;
  onChange?: (value: string) => void;
}

export const TimezoneSelect: React.FC<Props> = ({ defaultValue, onChange }) => {
  const handleChange = (newValue: { label: string; value: string }) => {
    if (onChange) {
      onChange(newValue.value);
    }
  };

  return (
    <VuiBox mb={3}>
      <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <VuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
          color="white"
        >
          Timezone
        </VuiTypography>
      </VuiBox>
      <VuiSelect
        //@ts-ignore
        defaultValue={{
          label: defaultValue,
          value: defaultValue,
        }}
        options={TIMEZONE_OPTIONS}
        onChange={handleChange}
      />
    </VuiBox>
  );
};

//@ts-ignore
const TIMEZONE_OPTIONS = Intl.supportedValuesOf("timeZone").map((timezone) => ({
  value: timezone,
  label: timezone,
}));
