import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setChannels } from "redux/actions";
import ShopifyOrderService from "services/ShopifyOrderService";
import { useApi } from "hooks/useAPI";
import { Merchant } from "types/merchant";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiSelect from "components/VuiSelect";

interface SalesChannel {
  channel: string;
}

interface Props {
  selectedMerchant: Merchant;
  channels: SalesChannel[];
  setChannels: (payload: any) => void;
  onChange?: (value: string[]) => void;
  selectedChannels: string[];
}

const ChannelSelector: React.FC<Props> = ({
  selectedMerchant,
  selectedChannels,
  channels,
  setChannels,
  onChange,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const { getToken } = useApi();

  useEffect(() => {
    const fetchSalesChannelsData = async () => {
      setLoading(true);

      const token = getToken ? await getToken() : undefined;
      if (!token) {
        setLoading(false);
        return;
      }

      try {
        const data = await ShopifyOrderService.getChannels({
          token,
          merchantId: selectedMerchant.id,
        });
        setChannels({ channels: data });
      } catch (error) {
        console.error("Failed to fetch sales channels:", error);
      }

      setLoading(false);
    };

    fetchSalesChannelsData();
  }, [selectedMerchant, getToken, setChannels]);

  const handleChange = (newValue: { value: string; label: string }[]) => {
    if (onChange) {
      onChange(newValue.map((item) => item.value));
    }
  };

  return (
    <>
      <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <VuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          color="white"
        >
          Sales Channels
        </VuiTypography>
      </VuiBox>
      <VuiSelect
        //@ts-ignore
        value={selectedChannels.map((channel) => ({
          value: channel,
          label: channelHumanName(channel),
        }))}
        options={
          channels?.map((channel) => ({
            value: channel.channel,
            label: channelHumanName(channel.channel),
          })) || []
        }
        size="large"
        isMulti
        onChange={handleChange}
      />
    </>
  );
};

const channelHumanName = (channel: string | null) => {
  if (channel === null) {
    return "";
  }
  const formattedChannel = channel.replace(/_/g, " ");
  return formattedChannel.charAt(0).toUpperCase() + formattedChannel.slice(1);
};

const mapStateToProps = ({
  merchants,
  common,
}: {
  merchants: any;
  common: any;
}) => ({
  selectedMerchant: merchants.selectedMerchant,
  channels: common.channels,
});

const mapDispatchToProps = {
  setChannels,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelSelector);
