import axios from "../interceptors";

const AppService = {};

AppService.facebookInstall = async (data, token) =>
  axios.put("app/facebook/install", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
AppService.googleInstall = async (data, token) =>
  axios.put(`app/google/install`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
AppService.createSubscription = async (data) =>
  axios.post("app/shopify/subscriptions", data);
AppService.getSubscription = async (merchantId, all) =>
  axios.get(
    `app/shopify/subscriptions?merchantId=${merchantId}` +
      (all ? "&all=true" : "")
  );
AppService.cancelSubscription = async (merchantId, chargeId) =>
  axios.delete(`app/shopify/subscriptions/${merchantId}/${chargeId}`);

export default AppService;
