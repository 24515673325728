import { DateRange, PickersShortcutsItem } from "@mui/x-date-pickers-pro";
import {
  addDays,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns";

export const shortcutsItems: PickersShortcutsItem<DateRange<Date>>[] = [
  {
    label: "Today",
    getValue: () => {
      const today = new Date();
      return [today, today];
    },
  },
  {
    label: "Yesterday",
    getValue: () => {
      const today = new Date();
      const yesterday = subDays(today, 1);
      return [yesterday, yesterday];
    },
  },
  {
    label: "This Week",
    getValue: () => {
      const today = new Date();
      return [startOfWeek(today), endOfWeek(today)];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = new Date();
      const prevWeek = subDays(today, 7);
      return [startOfWeek(prevWeek), endOfWeek(prevWeek)];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = new Date();
      return [subDays(today, 7), today];
    },
  },
  {
    label: "Last 30 Days",
    getValue: () => {
      const today = new Date();
      return [subDays(today, 30), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = new Date();
      return [startOfMonth(today), endOfMonth(today)];
    },
  },
  {
    label: "Last Month",
    getValue: () => {
      const today = new Date();
      const lastMonth = subDays(startOfMonth(today), 1);
      return [startOfMonth(lastMonth), endOfMonth(lastMonth)];
    },
  },
  {
    label: "This Year",
    getValue: () => {
      const today = new Date();
      return [
        new Date(today.getFullYear(), 0, 1),
        new Date(today.getFullYear(), 11, 31),
      ];
    },
  },
  {
    label: "Last Year",
    getValue: () => {
      const lastYear = new Date().getFullYear() - 1;
      return [new Date(lastYear, 0, 1), new Date(lastYear, 11, 31)];
    },
  },
];
