import React, { useEffect, useState } from "react";
import VuiBox from "components/VuiBox";
import { Card, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import VuiTypography from "components/VuiTypography";
import Chart from "react-apexcharts";
const chartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    stacked: true,
    type: "bar",
    height: 350,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 0,
      columnWidth: "70%",
      barHeight: "100%",
      distributed: false,
      rangeBarOverlap: true,
      rangeBarGroupRows: false,
      colors: {
        ranges: [
          {
            from: 0,
            to: 0,
            color: undefined,
          },
        ],
        backgroundBarColors: [],
        backgroundBarOpacity: 1,
        backgroundBarRadius: 0,
      },
      dataLabels: {
        enabled: false,
      },
    },
  },
  fill: {
    colors: ["#34E2AB", "#3379EB"],
  },
  legend: {
    position: "top",
    horizontalAlign: "left",
    labels: {
      colors: ["#FFFFFF", "#FFFFFF"],
    },
    itemMargin: {
      horizontal: 10,
    },
    fontFamily: "inherit",
    fontSize: "12px",
    fontWeight: 400,
    markers: {
      width: 8,
      height: 8,
      radius: 12,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: true,
    },
  },
  tooltip: {
    theme: "dark",
    shared: true,
    intersect: false,
    y: {
      formatter: function (value) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value);
      },
    },
  },
};

const AOVChart = ({
  AOVGraphData,
  newCustomersGraphData,
  returningCustomersGraphData,
  totalAOV,
}) => {
  const { plotOptions, fill } = chartOptions;
  const [options, setOptions] = useState({});
  const [seriesBar, setSeriesBar] = useState([]);

  const monthNames = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const aov = (num1, num2, toFixed) => {
    if (Number(num1) > 0 && Number(num2) > 0 && toFixed === "toFixed") {
      return (num1 / num2).toFixed(2);
    } else if (Number(num1) > 0 && Number(num2) > 0 && toFixed === "") {
      return num1 / num2;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    let aovData = [];
    let newCustomersData = [];
    let returningCustomersData = [];
    if (
      AOVGraphData &&
      newCustomersGraphData &&
      returningCustomersGraphData &&
      (AOVGraphData !== newCustomersGraphData.length) !==
        returningCustomersGraphData.length
    ) {
      let newCustomersDates = [];
      let returningCustomersDates = [];
      let graphAOV = [...AOVGraphData];
      let graphDataReturningCustomers = [...returningCustomersGraphData];
      let graphDatanewCustomers = [...newCustomersGraphData];
      newCustomersDates = graphDatanewCustomers.map((value) => value._id);
      returningCustomersDates = graphDataReturningCustomers.map(
        (value) => value._id
      );
      if (newCustomersGraphData.length > returningCustomersGraphData.length) {
        newCustomersDates.forEach((value) => {
          if (!returningCustomersDates.includes(value)) {
            graphDataReturningCustomers.push({
              _id: value,
              totalAmount: 0,
            });
          }
        });
      } else {
        returningCustomersDates.forEach((value) => {
          if (!newCustomersDates.includes(value)) {
            graphDatanewCustomers.push({
              _id: value,
              totalAmount: 0,
            });
          }
        });
      }
      AOVGraphData = graphAOV;
      newCustomersGraphData = graphDatanewCustomers;
      returningCustomersGraphData = graphDataReturningCustomers;
    }
    if (AOVGraphData && AOVGraphData.length) {
      AOVGraphData.sort((a, b) => (a._id > b._id ? 1 : -1));
      aovData = AOVGraphData.map((value) =>
        aov(value.totalAmount, value.count, "toFixed")
      );
    }
    if (newCustomersGraphData && newCustomersGraphData.length) {
      newCustomersGraphData.sort((a, b) => (a._id > b._id ? 1 : -1));
      newCustomersData = newCustomersGraphData.map((value) =>
        (value.totalAmount / value.count).toFixed(2)
      );
    }
    if (returningCustomersGraphData && returningCustomersGraphData.length) {
      returningCustomersGraphData.sort((a, b) => (a._id > b._id ? 1 : -1));
      returningCustomersData = returningCustomersGraphData.map((value) =>
        value.totalAmount
          ? aov(value.totalAmount, value.count, "toFixed")
          : aov(value.totalAmount, value.count, "")
      );
    }
    setOptions({
      xaxis: {
        lines: {
          show: false,
        },
        categories: newCustomersGraphData.map((value) => {
          const parts = value._id.toString().split("-");
          if (parts.length === 3) {
            return `${monthNames[parts[1]]} ${parts[2]}`;
          } else if (parts.length === 2) {
            return `${monthNames[parts[1]]} ${parts[0]}`;
          } else {
            return value._id.toString();
          }
        }),
        labels: {
          style: {
            colors: "#c8cfca",
            fontSize: "10px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c8cfca",
            fontSize: "10px",
          },
          formatter: function (value) {
            return "$" + value;
          },
        },
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        labels: {
          colors: "#FFFFFF",
        },
        itemMargin: {
          horizontal: 10,
        },
        fontFamily: "inherit",
        fontSize: "12px",
        fontWeight: 400,
        markers: {
          width: 8,
          height: 8,
          radius: 12,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions,
      fill,
      stroke: {
        curve: "smooth",
        width: [5, 0, 0],
      },
    });
    setSeriesBar([
      {
        data: aovData,
        name: "Sitewide AOV",
        type: "line",
        color: "#fff",
      },
      {
        name: "New Customers",
        type: "column",
        data: newCustomersData,
        color: "#2CD9FF",
      },
      {
        name: "Returning Customers",
        type: "column",
        data: returningCustomersData,
        color: "#0075FF",
      },
    ]);
  }, [returningCustomersGraphData, newCustomersGraphData]);

  const calculateAOV = (data) => {
    let aov = 0;
    let count = 0;
    for (let i = 0; i < data.length; i++) {
      aov += data[i].totalAmount;
      count += data[i].count;
    }
    let result = (aov / count).toFixed(2);
    if (result === undefined || result === "NaN" || result == null) {
      return "$0.00";
    } else {
      return `$${result}`;
    }
  };

  return (
    <Card>
      <VuiBox sx={{ height: "100%" }}>
        <VuiTypography
          variant="lg"
          color="white"
          fontWeight="bold"
          mb="5px"
          ml="10px"
        >
          New vs. Returning Customer AOV
        </VuiTypography>
        <VuiBox sx={{ height: "500px" }}>
          <Chart
            title={"New vs. Returning Customer AOV"}
            options={options}
            series={seriesBar}
            type={"bar"}
            key={"bar"}
            width="100%"
            height="500"
          />
        </VuiBox>
      </VuiBox>
      <Grid container spacing="50px">
        <Grid item xs={2}>
          <Stack direction="row" spacing="10px" mb="6px">
            <VuiTypography color="text" variant="button" fontWeight="medium">
              Sitewide AOV
            </VuiTypography>
          </Stack>
          <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
            ${totalAOV.toFixed(2)}
          </VuiTypography>
        </Grid>
        <Grid item xs={6} md={3} lg={3}>
          <Stack direction="row" spacing="10px" mb="6px">
            <VuiTypography color="text" variant="button" fontWeight="medium">
              AOV of New Customers
            </VuiTypography>
          </Stack>
          <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
            {calculateAOV(newCustomersGraphData)}
          </VuiTypography>
        </Grid>
        <Grid item xs={6} md={3} lg={3}>
          <Stack direction="row" spacing="10px" mb="6px">
            <VuiTypography color="text" variant="button" fontWeight="medium">
              AOV of Returning Customers
            </VuiTypography>
          </Stack>
          <VuiTypography color="white" variant="lg" fontWeight="bold" mb="8px">
            {calculateAOV(returningCustomersGraphData)}
          </VuiTypography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default AOVChart;
