import {
  autoUpdate,
  flip,
  FloatingPortal,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
} from "@floating-ui/react";
import { BaseWidget } from "./BaseWidget";
import { useState } from "react";
import { SpendSummary } from "services/SalesService";
import { BsFillEyeFill } from "react-icons/bs";
import { Card, Stack } from "@mui/material";
import VuiTypography from "components/VuiTypography";

interface Props {
  loading: boolean;
  currency: string;
  spendSummary?: SpendSummary;
}

export const AdSpendWidget = ({ loading, currency, spendSummary }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom",
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate, // Automatically update position
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
  ]);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        <BaseWidget
          title="Ad Spend"
          value={spendSummary?.value || 0}
          compareValue={spendSummary?.compareValue || 0}
          loading={loading}
          currency={currency}
          downIsGood
          subtitle="Total spent on ads"
          showHoverIndicator
          icon={<BsFillEyeFill color="white" />}
        />
      </div>
      <FloatingPortal>
        {isOpen && !loading && (
          <Card
            ref={refs.setFloating}
            {...getFloatingProps()}
            role="tooltip"
            style={{
              ...floatingStyles,
              width: 200,
              opacity: isOpen ? 1 : 0,
              transition: "opacity 0.2s",
              padding: "16px",
            }}
          >
            <Stack spacing={2}>
              <VuiTypography
                variant="body2"
                color="white"
                opacity={0.7}
                textTransform="capitalize"
                fontWeight="medium"
              >
                Spend breakdown
              </VuiTypography>

              <Stack spacing={1}>
                <Stack direction="row" justifyContent="space-between">
                  <VuiTypography
                    variant="caption"
                    color="white"
                    fontWeight="regular"
                  >
                    Facebook:
                  </VuiTypography>
                  <VuiTypography
                    variant="caption"
                    color="white"
                    fontWeight="regular"
                  >
                    {spendSummary?.breakdown?.facebook.toLocaleString("en-US", {
                      style: "currency",
                      currency: currency,
                    })}
                  </VuiTypography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <VuiTypography
                    variant="caption"
                    color="white"
                    fontWeight="regular"
                  >
                    Google:
                  </VuiTypography>
                  <VuiTypography
                    variant="caption"
                    color="white"
                    fontWeight="regular"
                  >
                    {spendSummary?.breakdown?.google.toLocaleString("en-US", {
                      style: "currency",
                      currency: currency,
                    })}
                  </VuiTypography>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        )}
      </FloatingPortal>
    </>
  );
};
