import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import { ApiProvider } from "hooks/useAPI";
import { VisionUIControllerProvider } from "context";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { LicenseInfo } from "@mui/x-license";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "assets/theme";
import { SnackbarProvider } from "notistack";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || "");

function App() {
  return (
    <div className="App">
      <KindeProvider
        isDangerouslyUseLocalStorage={process.env.NODE_ENV === "development"}
        clientId="dd77fa70e0b7442cabc1ed8cc02c947e"
        domain="https://dailymentor.kinde.com"
        logoutUri={window.location.origin}
        redirectUri={`${window.location.origin}/auth/callback`}
      >
        <Provider store={store}>
          <ApiProvider>
            <SnackbarProvider>
              <VisionUIControllerProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <PersistGate loading={null} persistor={persistor}>
                    <Router>
                      <Switch>
                        <ThemeProvider theme={theme}>
                          <CssBaseline />
                          <Route path="/" component={Views} />
                        </ThemeProvider>
                      </Switch>
                    </Router>
                  </PersistGate>
                </LocalizationProvider>
              </VisionUIControllerProvider>
            </SnackbarProvider>
          </ApiProvider>
        </Provider>
      </KindeProvider>
    </div>
  );
}

export default App;
