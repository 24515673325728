import { IDailyTarget, IMonthlyTarget, TargetValues } from "types/targets";

interface GetMonthlyTargetsResponse {
  data: IMonthlyTarget[];
}

interface GetDailyTargetsResponse {
  data: IDailyTarget[];
}

interface SetMonthlyTargetResponse {
  data: IMonthlyTarget;
}

interface SetDailyTargetResponse {
  data: IDailyTarget;
}

interface SetMonthlyTargetBody {
  merchantId: string;
  month: string;
  year: string;
  values: TargetValues;
}

interface SetDailyTargetBody {
  merchantId: string;
  monthlyTargetId: string;
  values: {
    id: string;
    values: TargetValues;
  }[];
}

type GetFunction = <T>(url: string) => Promise<T>;
type PostFunction = <T>(url: string, data: any) => Promise<T>;
export class TargetsService {
  private get: GetFunction;
  private post: PostFunction;

  constructor(getFn: GetFunction, postFn: PostFunction) {
    this.get = getFn;
    this.post = postFn;
  }

  async getMonthlyTargets({
    merchantId,
    startDate,
    endDate,
  }: {
    merchantId: string;
    startDate: string;
    endDate: string;
  }): Promise<GetMonthlyTargetsResponse> {
    const response = await this.get<GetMonthlyTargetsResponse>(
      `targets/monthly?merchantId=${merchantId}&startDate=${startDate}&endDate=${endDate}`
    );
    return response;
  }

  async getDailyTargets({
    merchantId,
    monthlyTargetId,
  }: {
    merchantId: string;
    monthlyTargetId: string;
  }): Promise<GetDailyTargetsResponse> {
    const response = await this.get<GetDailyTargetsResponse>(
      `targets/daily?merchantId=${merchantId}&monthlyTargetId=${monthlyTargetId}`
    );
    return response;
  }

  async setMonthlyTarget(
    data: SetMonthlyTargetBody
  ): Promise<SetMonthlyTargetResponse> {
    const response = await this.post<SetMonthlyTargetResponse>(
      `targets/monthly/set`,
      data
    );
    return response;
  }

  async setDailyTarget(
    data: SetDailyTargetBody
  ): Promise<SetDailyTargetResponse> {
    const response = await this.post<SetDailyTargetResponse>(
      `targets/daily/set`,
      data
    );
    return response;
  }
}
