import { Card, CardContent, Stack } from "@mui/material";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import { useHistory } from "react-router-dom";

const WarningMessage = ({
  text,
  detail,
  url,
  actionText,
}: {
  text: string;
  detail?: string;
  url?: string;
  actionText?: string;
}) => {
  const history = useHistory();
  return (
    <Card>
      <CardContent>
        <Stack
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <VuiTypography variant="h2" sx={{ color: "#fff" }}>
            {text}
          </VuiTypography>
          {detail && (
            <VuiTypography variant="body1" sx={{ color: "#fff" }}>
              {detail}
            </VuiTypography>
          )}
          {url && actionText && (
            //@ts-ignore
            <VuiButton onClick={() => history.push(url)}>
              {actionText}
            </VuiButton>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default WarningMessage;
