import { useState } from "react";
import { useDispatch } from "react-redux";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  List,
  ListItem,
  ListItemButton,
  Radio,
  Paper,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import VuiButton from "components/VuiButton";
import { selectedMerchant } from "redux/actions";
import { env } from "configs/EnvironmentConfig";
import { useApi } from "hooks/useAPI";
import AppService from "../../services/AppService";

const { FACEBOOK_APP_ID } = env;

const FacebookConnect = ({ merchant, title }) => {
  const { getToken } = useApi();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const [accountIds, setAccountIds] = useState([]);
  const [accountId, setAccountId] = useState("");
  const [search, setSearch] = useState([]);

  const handleOk = () => setShowModal(false);
  const handleCancel = () => setShowModal(false);

  const responseFacebook = async (response) => {
    if (response?.accessToken && response?.id) {
      try {
        const { id, accessToken } = response;
        const data = {
          merchantId: merchant?.id,
          shortLivedToken: accessToken,
          accountId: id,
        };
        const token = getToken ? await getToken() : "";
        const accountIds = await AppService.facebookInstall(data, token);
        setAccountIds(accountIds);
        setShowModal(true);
      } catch (error) {
        enqueueSnackbar("Facebook account can't be added!", {
          variant: "error",
        });
      }
    }
  };

  const addBussinessId = async () => {
    try {
      const data = {
        merchantId: merchant?.id,
        accountId,
      };
      const token = getToken ? await getToken() : "";
      const apiResponse = await AppService.facebookInstall(data, token);
      dispatch(selectedMerchant(apiResponse));
      enqueueSnackbar("Facebook account added!", { variant: "success" });
      handleOk();
    } catch (error) {
      enqueueSnackbar("Failed to add Facebook account", { variant: "error" });
    }
  };

  const onSearch = (query) => {
    if (query !== "") {
      query = query.toLowerCase();
      const filter = accountIds.filter(
        (item) =>
          item?.name?.toLowerCase().includes(query) ||
          item?.id?.toLowerCase().includes(query)
      );
      setSearch(filter);
    } else {
      setSearch([]);
    }
  };

  return (
    <>
      <FacebookLogin
        appId={FACEBOOK_APP_ID}
        autoLoad={false}
        size="small"
        scope="ads_read, public_profile, email"
        callback={responseFacebook}
        render={(renderProps) => (
          <VuiButton variant="contained" onClick={renderProps.onClick}>
            {title}
          </VuiButton>
        )}
      />

      <Dialog
        open={showModal}
        onClose={handleCancel}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.87)",
            color: "white",
          },
        }}
      >
        <DialogTitle sx={{ color: "white !important" }}>
          Select a Facebook Account
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            {accountIds?.length > 0 && (
              <TextField
                fullWidth
                placeholder="Search by name or account id"
                onChange={(e) => onSearch(e.target.value)}
                sx={{
                  input: { color: "white" },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "rgba(255, 255, 255, 0.23)" },
                    "&:hover fieldset": { borderColor: "white" },
                  },
                }}
              />
            )}
            <List sx={{ width: "100%" }}>
              {(search.length > 0 ? search : accountIds).map((account) => (
                <ListItem key={account.id} disablePadding>
                  <ListItemButton
                    onClick={() => setAccountId(account.id)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Radio
                      checked={accountId === account.id}
                      sx={{
                        color: "white !important",
                      }}
                    />
                    <Stack>
                      <strong style={{ color: "white" }}>{account.name}</strong>
                      <span style={{ color: "rgba(255, 255, 255, 0.7)" }}>
                        {account.id}
                      </span>
                    </Stack>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Stack>
        </DialogContent>
        <DialogActions>
          <VuiButton variant="outlined" onClick={handleCancel}>
            Cancel
          </VuiButton>
          <VuiButton
            variant="contained"
            disabled={!accountId}
            onClick={addBussinessId}
          >
            OK
          </VuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FacebookConnect;
