import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

interface Props {
  title: string;
  value: number;
  compareValue: number;
  loading: boolean;
  downIsGood: boolean;
  subtitle?: string;
  valueFormatter?: (value: number) => string;
  icon?: React.ReactNode;
}

export const BaseWidget = ({
  title,
  value,
  compareValue,
  loading,
  downIsGood,
  subtitle,
  valueFormatter = (value) => value.toString(),
  icon,
}: Props) => {
  const showDifference = compareValue !== 0 && value !== compareValue;

  const calculatePercentageChange = () => {
    if (!showDifference) return undefined;

    const percentageChange = ((value - compareValue) / compareValue) * 100;
    const isPositiveChange = percentageChange > 0;
    const isGoodChange = downIsGood ? !isPositiveChange : isPositiveChange;

    return {
      color: isGoodChange ? "success" : "error",
      text: `${isPositiveChange ? "+" : ""}${percentageChange.toFixed(1)}%`,
    };
  };

  return (
    <MiniStatisticsCard
      title={{ text: title, fontWeight: "bold" }}
      count={valueFormatter(value)}
      // @ts-ignore
      percentage={calculatePercentageChange()}
      icon={icon}
      loading={loading}
    />
  );
};
