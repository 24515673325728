/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Route, useLocation, Switch, Redirect } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Vision UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Vision UI Dashboard PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Vision UI Dashboard PRO React routes
import routes from "../../routes";

// Vision UI Dashboard PRO React contexts
import { useVisionUIController, setMiniSidenav } from "context";

// Plugins custom css
import "assets/theme/base/plugins.css";

interface RouteType {
  layout?: string;
  path?: string;
  component?: React.ReactNode;
  collapse?: RouteType[];
  name?: string;
  key?: string;
}

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
    });

    setRtlCache(cacheRtl as any);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    // @ts-ignore
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (routes: RouteType[]) => {
    return routes.flatMap((route: RouteType, key: number): JSX.Element[] => {
      if (route.layout === "/admin") {
        return [
          <Route
            exact
            path={route.path} // Remove layout prefix since it's already in the path
            key={key}
            render={() => route.component}
          />,
        ];
      }
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      return [];
    });
  };

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache as any}>
      <ThemeProvider theme={themeRTL}>
        <CssBaseline />
        {layout === "/admin" && (
          <>
            <Sidenav
              brand="Daily Mentor"
              color={sidenavColor}
              brandName="Daily Mentor"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Switch>
          {getRoutes(routes)}
          <Redirect from="/" to="/app/dashboards/default" exact />
        </Switch>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            brand="Daily Mentor"
            color={sidenavColor}
            brandName="Daily Mentor"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Switch>
        {getRoutes(routes)}
        <Redirect from="/" to="/app/dashboards/default" exact />
      </Switch>
    </ThemeProvider>
  );
}
