/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// prop-types is a library for typechecking of props
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import colors from "assets/theme/base/colors";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Skeleton from "@mui/material/Skeleton";

interface TitleProps {
  fontWeight: "light" | "regular" | "medium" | "bold";
  text: string;
}

interface PercentageProps {
  color:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "dark"
    | "white";
  text: string | number;
}

interface IconProps {
  color:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "dark";
  component: React.ReactNode;
}

interface MiniStatisticsCardProps {
  bgColor?:
    | "white"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "dark";
  title?: TitleProps;
  count: string | number;
  percentage?: PercentageProps;
  icon?: IconProps | React.ReactNode;
  direction?: "right" | "left";
  loading?: boolean;
}

function MiniStatisticsCard({
  bgColor = "white",
  title = {
    fontWeight: "medium",
    text: "",
  },
  count,
  percentage = {
    color: "success",
    text: "",
  },
  icon,
  direction = "right",
  loading = false,
}: MiniStatisticsCardProps) {
  const { info } = colors;

  if (loading) {
    return (
      <Card sx={{ padding: "18px 22px", minHeight: "73px" }}>
        <VuiBox>
          <Grid container alignItems="center">
            {direction === "left" && (
              <Grid item>
                <Skeleton
                  variant="rounded"
                  width={45}
                  height={45}
                  sx={{ bgcolor: "rgba(255, 255, 255, 0.1)" }}
                />
              </Grid>
            )}
            <Grid item xs={direction === "right" && icon ? 8 : 12}>
              <VuiBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                <Skeleton
                  variant="text"
                  width={80}
                  height={20}
                  sx={{ bgcolor: "rgba(255, 255, 255, 0.1)" }}
                />
                <Skeleton
                  variant="text"
                  width={120}
                  height={24}
                  sx={{ bgcolor: "rgba(255, 255, 255, 0.1)" }}
                />
              </VuiBox>
            </Grid>
            {direction === "right" && icon && (
              <Grid item xs={4}>
                <VuiBox display="flex" justifyContent="flex-end">
                  <Skeleton
                    variant="rounded"
                    width={45}
                    height={45}
                    sx={{ bgcolor: "rgba(255, 255, 255, 0.1)" }}
                  />
                </VuiBox>
              </Grid>
            )}
          </Grid>
        </VuiBox>
      </Card>
    );
  }

  return (
    <Card sx={{ padding: "18px 22px" }}>
      <VuiBox>
        <VuiBox>
          <Grid container alignItems="center">
            {direction === "left" ? (
              <Grid item>
                <VuiBox
                  bgColor={info}
                  color="#fff"
                  width="45px"
                  height="45px"
                  borderRadius="lg"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  {icon}
                </VuiBox>
              </Grid>
            ) : null}
            <Grid item xs={direction === "right" && icon ? 8 : 12}>
              <VuiBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                <VuiTypography
                  variant="caption"
                  color={bgColor === "white" ? "text" : "white"}
                  opacity={bgColor === "white" ? 1 : 0.7}
                  textTransform="capitalize"
                  fontWeight={title.fontWeight}
                >
                  {title.text}
                </VuiTypography>
                <VuiTypography
                  variant="subtitle1"
                  fontWeight="bold"
                  color="white"
                  sx={({ breakpoints }: { breakpoints: any }) => ({
                    [breakpoints.only("lg")]: {
                      fontSize: "16px",
                    },
                  })}
                >
                  {count}{" "}
                  <VuiTypography
                    variant="button"
                    color={percentage.color}
                    fontWeight="bold"
                    sx={({ breakpoints }: { breakpoints: any }) => ({
                      [breakpoints.only("lg")]: {
                        fontSize: "10px",
                      },
                      [breakpoints.only("xl")]: {
                        fontSize: "12px",
                      },
                    })}
                  >
                    {percentage.text}
                  </VuiTypography>
                </VuiTypography>
              </VuiBox>
            </Grid>
            {icon && direction === "right" ? (
              <Grid item xs={4}>
                <VuiBox
                  bgColor="#0075FF"
                  color="white"
                  width="45px"
                  height="45px"
                  marginLeft="auto"
                  borderRadius="lg"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  {icon}
                </VuiBox>
              </Grid>
            ) : null}
          </Grid>
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default MiniStatisticsCard;
