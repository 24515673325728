import React, { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import { useApi } from "hooks/useAPI";
import MerchantService from "services/MerchantService";
import { useSelector } from "react-redux";

const ShopifyCollectionStatus = () => {
  const [progress, setProgress] = useState(0);
  const [latestCollectionDate, setLatestCollectionDate] = useState("");
  const { getToken } = useApi();
  const selectedMer = useSelector(
    (state: any) => state.merchants.selectedMerchant
  );

  useEffect(() => {
    const fetchStatus = async () => {
      if (selectedMer?.id && getToken) {
        try {
          const token = await getToken();
          if (token) {
            const response = await MerchantService.getShopifyCollectionProgress(selectedMer.id, token);
            setProgress(response.data.progress);
            setLatestCollectionDate(response.data.lastCollectionDate);
          } else {
            console.error("Token is undefined");
          }
        } catch (error) {
          console.error("Error fetching collection status:", error);
        }
      }
    };

    fetchStatus();
  }, [selectedMer?.id, getToken]);

  return (
    <VuiBox>
      <LinearProgress
        variant="determinate"
        sx={{
          background: "rgba(16, 142, 233, 0.3)",
          "& .MuiLinearProgress-bar": {
            width: `${progress}%`,
            background: "linear-gradient(90deg, #108ee9, #87d068)",
          },
        }}
      />
      <VuiTypography variant="caption" color="text" mt={1} display="block">
        {progress === 0 && !latestCollectionDate
          ? "No information available"
          : `${progress}% complete: Up to ${
              latestCollectionDate
                ? new Date(latestCollectionDate).toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  })
                : 'N/A'
            }`
        }
      </VuiTypography>
    </VuiBox>
  );
};

export default ShopifyCollectionStatus; 