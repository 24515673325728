import React from "react";
import { connect } from "react-redux";
import { setChannels } from "redux/actions";

import { Merchant } from "types/merchant";
import VuiSelect from "components/VuiSelect";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

const AttributionWindows = ["1d_click", "7d_click", "1d_view"];

interface Props {
  selectedMerchant: Merchant;
  onChange?: (value: string) => void;
}

const FacebookAttributionWindowClickSelector: React.FC<Props> = ({
  selectedMerchant,
  onChange,
}) => {
  const handleChange = (newValue: { label: string; value: string }) => {
    if (onChange) {
      onChange(newValue.value);
    }
  };

  return (
    <VuiBox mb={3}>
      <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <VuiTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
          color="white"
        >
          Click Window
        </VuiTypography>
      </VuiBox>
      <VuiSelect
        //@ts-ignore
        defaultValue={{
          label: humanReadableTitle(
            selectedMerchant.facebookAttributionWindowClick || ""
          ),
          value: selectedMerchant.facebookAttributionWindowClick || "",
        }}
        options={AttributionWindows.map((window) => ({
          label: humanReadableTitle(window),
          value: window,
        }))}
        label="Click Window"
        onChange={handleChange}
      />
    </VuiBox>
  );
};

const humanReadableTitle = (window: string) => {
  switch (window) {
    case "1d_click":
      return "1 Day Click";
    case "7d_click":
      return "7 Day Click";
    case "1d_view":
      return "1 Day View";
    default:
      return window;
  }
};

const mapStateToProps = ({ merchants }: { merchants: any }) => ({
  selectedMerchant: merchants.selectedMerchant,
});

const mapDispatchToProps = {
  setChannels,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FacebookAttributionWindowClickSelector);
