import { Merchant } from "types/merchant";
import axios from "../interceptors";

interface CreateMerchantRequest {
  code: string;
  shop: string;
  hmac: string;
  state: string;
  host: string;
  userId: string;
}

interface IMerchantService {
  create: (data: CreateMerchantRequest, token?: string) => Promise<Merchant>;
  post: (data: any) => Promise<any>;
  updateMerchant: (data: any, token: string) => Promise<any>;
  getMerchant: (token: string) => Promise<any>;
  getMerchantByShopName: (shopName: string, hmac: string) => Promise<any>;
  addUserToMerchant: (data: any) => Promise<any>;
  getShopifyCollectionProgress: (merchantId: string, token: string) => Promise<any>;
}

//@ts-expect-error
const MerchantService: IMerchantService = {};

export const MerchantServiceRoutes = {
  create: "merchant",
  post: "merchant/check",
  updateMerchant: "merchant",
  getMerchant: "merchant/getUserMerchants",
  getMerchantByShopName: "merchant/getMerchantByShopName",
  addUserToMerchant: "merchant/addUserToMerchant",
  getShopifyCollectionProgress: "merchant/getShopifyCollectionProgress",
};

MerchantService.create = async (data, token) =>
  axios.post("merchant", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

MerchantService.post = async (data) => axios.post("merchant/check", data);

MerchantService.updateMerchant = async (data, token) => {
  return axios.put("merchant", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

MerchantService.getMerchant = async (token: string) => {
  return axios.get(`merchant/getUserMerchants`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

MerchantService.getMerchantByShopName = async (shopName, hmac) =>
  axios.get(`merchant/getMerchantByShopName?shopName=${shopName}&hmac=${hmac}`);

MerchantService.addUserToMerchant = async (data) =>
  axios.put(`merchant/addUserToMerchant`, data);

MerchantService.getShopifyCollectionProgress = async (merchantId, token) =>
  axios.get(`merchant/getShopifyCollectionProgress?merchantId=${merchantId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export default MerchantService;
