/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { forwardRef } from "react";
import VuiBoxRoot from "components/VuiBox/VuiBoxRoot";

// Define the props interface
interface VuiBoxProps {
  variant?: "contained" | "gradient";
  bgColor?: string;
  color?: string;
  opacity?: number;
  borderRadius?: string;
  shadow?: string;
  [key: string]: any; // for rest props
}

// Custom styles for VuiBox

const VuiBox = forwardRef<HTMLDivElement, VuiBoxProps>(
  (
    { variant, bgColor, color, opacity, borderRadius, shadow, ...rest },
    ref
  ) => (
    <VuiBoxRoot
      {...rest}
      ref={ref}
      // @ts-ignore
      ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow }}
    />
  )
);

export default VuiBox;
