import { BaseWidget } from "./BaseWidget";

interface Props {
  value: number;
  compareValue: number;
  loading: boolean;
}

export const ReturningCustomerOrders = ({
  value,
  compareValue,
  loading,
}: Props) => {
  return (
    <BaseWidget
      title="Returning Customer Orders"
      value={value}
      compareValue={compareValue}
      loading={loading}
      downIsGood={false}
      subtitle="Rrders from returning customers"
      valueFormatter={(value) => value.toLocaleString()}
    />
  );
};
