import React, { useState } from "react";
import AvatarStatus from "components/shared-components/AvatarStatus";
import { connect } from "react-redux";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import { Card, CardHeader, Grid, Button } from "@mui/material";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";

function ConnectPage() {
  const [loading, setLoading] = useState(false);

  const startLogin = async () => {
    setLoading(true);
    setLoading(false);
    // @ts-ignore
    window.location.href = "https://apps.shopify.com/daily-mentor";
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card>
          <VuiTypography variant="h4" color="white" fontWeight="medium" mb={3}>
            Add an account
          </VuiTypography>
          <div className="mb-4">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} md={8}>
                <div>
                  <AvatarStatus src={"/img/shopify.png"} name="Shopify" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <VuiTypography variant="body1" color="white" mb={3}>
                  To connect your Merchant account, Install the Daily Mentor App
                  from the Shopify App Store
                </VuiTypography>
                {/* @ts-ignore */}
                <VuiButton
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={startLogin}
                >
                  Open Shopify App Store
                </VuiButton>
              </Grid>
            </Grid>
          </div>
        </Card>
      </VuiBox>
    </DashboardLayout>
  );
}

const mapStateToProps = ({ auth }: { auth: any }) => {
  const { user } = auth;
  return { user };
};

export default connect(mapStateToProps, null)(ConnectPage);
