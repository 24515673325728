import { BaseWidget } from "./BaseWidget";

interface Props {
  value: number;
  compareValue: number;
  loading: boolean;
}

export const ReturningCustomers = ({ value, compareValue, loading }: Props) => {
  return (
    <BaseWidget
      title="Returning Customers"
      value={value}
      compareValue={compareValue}
      loading={loading}
      downIsGood={false}
      subtitle="Total returning customers"
      valueFormatter={(value) => value.toLocaleString()}
    />
  );
};
