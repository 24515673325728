/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// @mui material components
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

interface OwnerState {
  color?: string;
  textTransform?: string;
  verticalAlign?: string;
  fontWeight?: "light" | "regular" | "medium" | "bold";
  opacity?: number;
  textGradient?: boolean;
}

interface Gradient {
  main: string;
  state: string;
  deg?: number;
}

interface GradientPalette {
  [key: string]: Gradient;
  primary: Gradient;
}

export default styled(Typography)<{ ownerState: OwnerState }>(
  // @ts-ignore
  ({ theme, ownerState }) => {
    // @ts-ignore
    const { palette, typography, functions } = theme;
    const {
      color,
      textTransform,
      verticalAlign,
      fontWeight,
      opacity,
      textGradient,
    } = ownerState;

    const { gradients, transparent, white } = palette as unknown as {
      gradients: GradientPalette;
      transparent: { main: string };
      white: { main: string };
    };

    const {
      fontWeightLight,
      fontWeightRegular,
      fontWeightMedium,
      fontWeightBold,
    } = typography;
    const { linearGradient } = functions;

    // fontWeight styles
    const fontWeights = {
      light: fontWeightLight,
      regular: fontWeightRegular,
      medium: fontWeightMedium,
      bold: fontWeightBold,
    };

    // styles for the typography with textGradient={true}
    const gradientStyles = () => ({
      backgroundImage:
        color !== "inherit" &&
        color !== "text" &&
        color !== "white" &&
        // @ts-ignore
        gradients[color]
          ? linearGradient(
              // @ts-ignore
              gradients[color].main,
              // @ts-ignore
              gradients[color].state,
              // @ts-ignore
              gradients[color].deg
            )
          : linearGradient(gradients.primary.main, gradients.primary.state),
      display: "inline-block",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: transparent.main,
      position: "relative",
      zIndex: 1,
    });

    return {
      opacity,
      textTransform,
      verticalAlign,
      textDecoration: "none",
      color:
        // @ts-ignore
        color === "inherit" || !palette[color]
          ? "inherit"
          : // @ts-ignore
            palette[color].main,
      // @ts-ignore
      fontWeight: fontWeights[fontWeight] && fontWeights[fontWeight],
      ...(textGradient && gradientStyles()),
    };
  }
);
