import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import { BsGearFill } from "react-icons/bs";

interface Props {
  value: number;
  compareValue: number;
  loading: boolean;
  currency: string;
}

export const MERWidget = ({ value, compareValue, loading }: Props) => {
  return (
    <MiniStatisticsCard
      title={{ text: "MER", fontWeight: "bold" }}
      count={value.toFixed(2)}
      // subtitle="Revenue/Spend"
      icon={<BsGearFill color="white" />}
      loading={loading}
    />
  );
};
