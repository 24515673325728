import { Alert, Snackbar } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { format } from "date-fns";

import { useApi } from "hooks/useAPI";
import { useEffect, useState } from "react";
import { TargetsService } from "services/TargetsService";
import {
  IDailyTarget,
  IMonthlyTarget,
  TargetFields,
  titleForField,
} from "types/targets";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";

interface SetDailyTargetsModalProps {
  monthlyTarget?: IMonthlyTarget;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

export const SetDailyTargetsModal: React.FC<SetDailyTargetsModalProps> = ({
  monthlyTarget,
  isOpen,
  onClose,
  onSave,
}) => {
  const { get, post } = useApi();
  const [dailyTargets, setDailyTargets] = useState<IDailyTarget[]>([]);
  const [editedValues, setEditedValues] = useState<Record<string, any>>({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });

  useEffect(() => {
    if (monthlyTarget) {
      fetchDailyTargets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyTarget]);

  const fetchDailyTargets = async () => {
    if (!monthlyTarget) return;

    const targetsService = new TargetsService(get, post);
    const result = await targetsService.getDailyTargets({
      merchantId: monthlyTarget.merchantId,
      monthlyTargetId: monthlyTarget.id,
    });
    setDailyTargets(result.data);
  };

  const handleInputChange = (
    targetId: string,
    field: string,
    value: string
  ) => {
    setEditedValues((prev) => ({
      ...prev,
      [targetId]: {
        ...(prev[targetId] || {}),
        [field]: parseFloat(value) || 0,
      },
    }));
  };

  const handleSave = async () => {
    if (!monthlyTarget) return;

    try {
      const targetsService = new TargetsService(get, post);
      const updatedTargets = Object.entries(editedValues).map(
        ([targetId, values]) => ({
          id: targetId,
          values: {
            ...values,
          },
        })
      );

      await targetsService.setDailyTarget({
        merchantId: monthlyTarget?.merchantId,
        monthlyTargetId: monthlyTarget?.id,
        values: updatedTargets,
      });

      setSnackbar({
        open: true,
        message: "Daily targets updated successfully",
        severity: "success",
      });

      setEditedValues({});
      onSave();
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to update daily targets",
        severity: "error",
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        maxWidth="xl"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: "rgba(0,0,0,1)",
            backgroundImage: "none",
          },
        }}
      >
        <DialogTitle>
          <VuiTypography variant="h6" color="white">
            Set Daily Targets for{" "}
            {monthlyTarget &&
              new Date(monthlyTarget.date).toLocaleString("default", {
                month: "long",
                year: "numeric",
              })}
          </VuiTypography>
        </DialogTitle>
        <DialogContent>
          <VuiBox mt={2}>
            <TableContainer
              sx={{
                maxWidth: "100%",
                overflowX: "auto",
              }}
            >
              <Table
                sx={{
                  tableLayout: "fixed",
                  width: "100%",
                  minWidth: "800px",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        minWidth: "110px",
                      }}
                    >
                      <VuiTypography variant="caption" color="text">
                        Date
                      </VuiTypography>
                    </TableCell>
                    {Object.values(TargetFields).map((field) => (
                      <TableCell
                        key={field}
                        sx={{
                          minWidth: "120px",
                        }}
                      >
                        <VuiTypography variant="caption" color="text">
                          {titleForField(field)}
                        </VuiTypography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dailyTargets.map((target) => (
                    <TableRow key={target.id}>
                      <TableCell>
                        <VuiTypography variant="button" color="white">
                          {format(new Date(target.date), "EEE do")}
                        </VuiTypography>
                      </TableCell>
                      {Object.values(TargetFields).map((field) => (
                        <TableCell
                          key={field}
                          sx={{
                            minWidth: "220px",
                          }}
                        >
                          <VuiInput
                            //@ts-ignore
                            inputProps={{
                              type: "number",
                              min: 0, // assuming targets can't be negative
                            }}
                            value={
                              editedValues[target.id]?.[field] ??
                              target[field as keyof IDailyTarget] ??
                              0
                            }
                            onChange={(e: any) =>
                              handleInputChange(
                                target.id,
                                field,
                                e.target.value
                              )
                            }
                            sx={{
                              width: "110px",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "rgba(255, 255, 255, 0.23)",
                                },
                                "&:hover fieldset": {
                                  borderColor: "rgba(255, 255, 255, 0.23)",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "primary.main",
                                },
                              },
                            }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </VuiBox>
        </DialogContent>
        <DialogActions>
          {/* @ts-ignore */}
          <VuiButton onClick={onClose} color="primary">
            Cancel
          </VuiButton>
          {/* @ts-ignore */}
          <VuiButton
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={Object.keys(editedValues).length === 0}
          >
            Save Changes
          </VuiButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};
