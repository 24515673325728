import { BaseWidget } from "./BaseWidget";

interface Props {
  value: number;
  compareValue: number;
  loading: boolean;
}

export const TotalOrders = ({ value, compareValue, loading }: Props) => {
  return (
    <BaseWidget
      title="Total Shopfiy Orders"
      value={value}
      compareValue={compareValue}
      loading={loading}
      downIsGood={false}
      subtitle="Total orders from Shopify"
      valueFormatter={(value) => value.toLocaleString()}
    />
  );
};
