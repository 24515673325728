/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// @mui material components
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

// Define types for the theme and ownerState
interface ThemeType {
  palette: {
    gradients: Record<string, { main: string; state: string }>;
    grey: Record<number, string>;
    white: { main: string };
    [key: string]: any;
  };
  functions: {
    linearGradient: (main: string, state: string) => string;
  };
  borders: {
    borderRadius: Record<string, string | number>;
  };
  boxShadows: Record<string, string>;
}

interface OwnerStateType {
  variant?: "gradient" | "contained";
  bgColor?: string;
  color?: string;
  opacity?: number;
  borderRadius?: string;
  shadow?: string;
}

export default styled(Box)<OwnerStateType>(
  // @ts-ignore
  ({ theme, ownerState }: { theme: ThemeType; ownerState: OwnerStateType }) => {
    const { palette, functions, borders, boxShadows } = theme;
    const { variant, bgColor, color, opacity, borderRadius, shadow } =
      ownerState;

    const { gradients, grey, white } = palette;
    const { linearGradient } = functions;
    const { borderRadius: radius } = borders;

    const greyColors = {
      "grey-100": grey[100],
      "grey-200": grey[200],
      "grey-300": grey[300],
      "grey-400": grey[400],
      "grey-500": grey[500],
      "grey-600": grey[600],
      "grey-700": grey[700],
      "grey-800": grey[800],
      "grey-900": grey[900],
    };

    const validGradients = [
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ];

    const validColors = [
      "transparent",
      "white",
      "black",
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "text",
      "grey-100",
      "grey-200",
      "grey-300",
      "grey-400",
      "grey-500",
      "grey-600",
      "grey-700",
      "grey-800",
      "grey-900",
    ];

    const validBorderRadius = ["xs", "sm", "md", "lg", "xl", "xxl", "section"];
    const validBoxShadows = ["xs", "sm", "md", "lg", "xl", "xxl", "inset"];

    // background value
    let backgroundValue = bgColor;

    if (variant === "gradient") {
      backgroundValue = validGradients.find((el) => el === bgColor)
        ? // @ts-ignore
          linearGradient(gradients[bgColor].main, gradients[bgColor].state)
        : white.main;
    } else if (validColors.find((el) => el === bgColor)) {
      // @ts-ignore
      backgroundValue = palette[bgColor]
        ? // @ts-ignore
          palette[bgColor].main
        : // @ts-ignore
          greyColors[bgColor];
    } else {
      backgroundValue = bgColor;
    }

    // color value
    let colorValue = color;

    if (validColors.find((el) => el === color)) {
      // @ts-ignore
      colorValue = palette[color] ? palette[color].main : greyColors[color];
    }

    // borderRadius value
    let borderRadiusValue = borderRadius;

    if (validBorderRadius.find((el) => el === borderRadius)) {
      // @ts-ignore
      borderRadiusValue = radius[borderRadius];
    }

    // boxShadow value
    let boxShadowValue = boxShadows;

    if (validBoxShadows.find((el) => el === shadow)) {
      // @ts-ignore
      boxShadowValue = boxShadows[shadow];
    }

    return {
      opacity,
      background: backgroundValue,
      color: colorValue,
      borderRadius: borderRadiusValue,
      boxShadow: boxShadowValue,
    };
  }
);
