/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { forwardRef } from "react";

// Custom styles for VuiInput
import VuiInputRoot from "components/VuiInput/VuiInputRoot";
import VuiInputWithIconRoot from "components/VuiInput/VuiInputWithIconRoot";
import VuiInputIconBoxRoot from "components/VuiInput/VuiInputIconBoxRoot";
import VuiInputIconRoot from "components/VuiInput/VuiInputIconRoot";

// Vision UI Dashboard PRO React contexts
import { useVisionUIController } from "context";

const VuiInput = forwardRef(
  ({ size, icon, error, success, disabled, onPressEnter, ...rest }, ref) => {
    let template;
    const [controller] = useVisionUIController();
    const { direction } = controller;
    const iconDirection = icon.direction;

    const handleKeyPress = (e) => {
      if (e.key === "Enter" && onPressEnter) {
        onPressEnter(e);
      }
    };

    const inputProps = {
      ...rest,
      onKeyPress: handleKeyPress,
      ownerState: { size, error, success, iconDirection, direction, disabled },
    };

    if (icon.component && icon.direction === "left") {
      template = (
        <VuiInputWithIconRoot
          ref={ref}
          ownerState={{ error, success, disabled }}
        >
          <VuiInputIconBoxRoot ownerState={{ size }}>
            <VuiInputIconRoot fontSize="small" ownerState={{ size }}>
              {icon.component}
            </VuiInputIconRoot>
          </VuiInputIconBoxRoot>
          <VuiInputRoot {...inputProps} />
        </VuiInputWithIconRoot>
      );
    } else if (icon.component && icon.direction === "right") {
      template = (
        <VuiInputWithIconRoot
          ref={ref}
          ownerState={{ error, success, disabled }}
        >
          <VuiInputRoot {...inputProps} />
          <VuiInputIconBoxRoot ownerState={{ size }}>
            <VuiInputIconRoot fontSize="small" ownerState={{ size }}>
              {icon.component}
            </VuiInputIconRoot>
          </VuiInputIconBoxRoot>
        </VuiInputWithIconRoot>
      );
    } else {
      template = (
        <VuiInputRoot
          {...inputProps}
          ref={ref}
          ownerState={{ size, error, success, disabled }}
        />
      );
    }

    return template;
  }
);

// Setting default values for the props of VuiInput
VuiInput.defaultProps = {
  size: "medium",
  icon: {
    component: false,
    direction: "none",
  },
  error: false,
  success: false,
  disabled: false,
};

export default VuiInput;
