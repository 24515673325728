import { IShippingMethod } from "types/shippingMethod";
import { IVariant } from "types/variant";

interface GetVariantsProps {
  merchantId: string;
  search?: string;
  hidden?: boolean;
  page?: number;
  limit?: number;
  sortBy?: "revenue" | "soldCount" | "lastSoldAt";
  sortOrder?: "asc" | "desc";
}

export interface Product {
  productID: string;
  productTitle: string;
  variants: IVariant[];
  lastSoldAt: Date | null;
  totalRevenue: number;
  totalSold: number;
}

export interface GetVariantsResponse {
  results: Product[];
  total: number;
  page: number;
  limit: number;
}

interface GetShippingMethodsProps {
  merchantId: string;
  search?: string;
  page?: number;
  limit?: number;
}

export interface GetShippingMethodsResponse {
  results: IShippingMethod[];
  total: number;
  page: number;
  limit: number;
}

type GetFunction = <T>(url: string) => Promise<T>;
type PutFunction = <T>(url: string, data: T) => Promise<T>;
type PostFunction = <T>(url: string, data: T) => Promise<T>;

export class CostsService {
  private get: GetFunction;
  private put: PutFunction;
  private post: PostFunction;

  constructor(getFn: GetFunction) {
    this.get = getFn;
    this.put = getFn;
    this.post = getFn;
  }

  async getVariants(params: GetVariantsProps): Promise<GetVariantsResponse> {
    const { merchantId, search, hidden, page, limit, sortBy, sortOrder } =
      params;
    const queryParams = new URLSearchParams({
      merchantId,
      ...(search && { search }),
      ...(hidden !== undefined && { hidden: hidden.toString() }),
      ...(page !== undefined && { page: page.toString() }),
      ...(limit !== undefined && { limit: limit.toString() }),
      ...(sortBy && { sortBy }),
      ...(sortOrder && { sortOrder }),
    }).toString();

    const response = await this.get<GetVariantsResponse>(
      `costs/variants?${queryParams}`
    );
    return response;
  }

  async getShippingMethods(
    params: GetShippingMethodsProps
  ): Promise<GetShippingMethodsResponse> {
    const { merchantId, search, page, limit } = params;
    const queryParams = new URLSearchParams({
      merchantId,
      ...(search && { search }),
      ...(page !== undefined && { page: page.toString() }),
      ...(limit !== undefined && { limit: limit.toString() }),
    }).toString();

    const response = await this.get<GetShippingMethodsResponse>(
      `costs/shippingMethods?${queryParams}`
    );
    return response;
  }

  async updateVariant(
    id: string,
    merchantId: string,
    data: Partial<IVariant>
  ): Promise<IVariant> {
    const response = await this.put<IVariant>(`costs/variants/${id}`, {
      merchantId,
      ...data,
    } as IVariant);
    return response;
  }

  async updateShippingMethod(
    id: string,
    merchantId: string,
    data: Partial<IShippingMethod>
  ): Promise<IShippingMethod> {
    const response = await this.put<IShippingMethod>(
      `costs/shippingMethods/${id}`,
      {
        merchantId,
        ...data,
      } as IShippingMethod
    );
    return response;
  }

  async setHiddenWithSearch(
    merchantId: string,
    search: string,
    hidden: boolean
  ): Promise<void> {
    await this.post("costs/variants/setHiddenWithSearch", {
      merchantId,
      search,
      hidden,
    });
  }

  async setVariantCostPriceWithSearch(
    merchantId: string,
    search: string,
    cost: number
  ): Promise<void> {
    await this.post("costs/variants/setCostPriceWithSearch", {
      merchantId,
      search,
      cost,
    });
  }

  async setShippingCostCostPriceWithSearch(
    merchantId: string,
    search: string,
    cost: number
  ): Promise<void> {
    await this.post("costs/shippingMethods/setCostPriceWithSearch", {
      merchantId,
      search,
      cost,
    });
  }
}
