import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import VuiBox from "components/VuiBox";
import { Card } from "@mui/material";
import VuiTypography from "components/VuiTypography";

const chartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    stacked: true,
    type: "bar",
    height: 350,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 0,
      columnWidth: "70%",
      barHeight: "100%",
      distributed: false,
      rangeBarOverlap: true,
      rangeBarGroupRows: false,
      colors: {
        ranges: [
          {
            from: 0,
            to: 0,
            color: undefined,
          },
        ],
        backgroundBarColors: [],
        backgroundBarOpacity: 1,
        backgroundBarRadius: 0,
      },
      dataLabels: {
        enabled: false,
      },
    },
  },
  colors: ["#34E2AB", "#3379EB"],
  legend: {
    position: "top",
    horizontalAlign: "left",
    labels: {
      colors: ["#FFFFFF", "#FFFFFF"],
    },
    itemMargin: {
      horizontal: 10,
    },
    fontFamily: "inherit",
    fontSize: "12px",
    fontWeight: 400,
    markers: {
      width: 8,
      height: 8,
      radius: 12,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: true,
    },
  },
  tooltip: {
    theme: "dark",
    shared: true,
    intersect: false,
    y: {
      formatter: function (value) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value);
      },
    },
  },
  grid: {
    show: false,
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
    padding: {
      left: 0,
      right: 0,
    },
  },
  fill: {},
  stroke: {
    curve: "smooth",
    width: 2,
  },
};

const CustomersChart = ({
  newCustomersGraphData,
  returningCustomersGraphData,
}) => {
  const { chart, plotOptions } = chartOptions;
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const [type, setType] = useState("bar");
  const selectedMerchant = useSelector(
    (state) => state.merchants.selectedMerchant
  );

  const monthNames = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  useEffect(() => {
    let newCustomersData = [];
    let returningCustomersData = [];
    if (
      newCustomersGraphData &&
      returningCustomersGraphData &&
      newCustomersGraphData.length !== returningCustomersGraphData.length
    ) {
      let newCustomersDates = [];
      let returningCustomersDates = [];
      let graphDataReturningCustomers = [...returningCustomersGraphData];
      let graphDatanewCustomers = [...newCustomersGraphData];
      newCustomersDates = graphDatanewCustomers.map((value) => value._id);
      returningCustomersDates = graphDataReturningCustomers.map(
        (value) => value._id
      );
      if (newCustomersGraphData.length > returningCustomersGraphData.length) {
        newCustomersDates.forEach((value) => {
          if (!returningCustomersDates.includes(value)) {
            graphDataReturningCustomers.push({
              _id: value,
              totalAmount: 0,
            });
          }
        });
      } else {
        returningCustomersDates.forEach((value) => {
          if (!newCustomersDates.includes(value)) {
            graphDatanewCustomers.push({
              _id: value,
              totalAmount: 0,
            });
          }
        });
      }
      newCustomersGraphData = graphDatanewCustomers;
      returningCustomersGraphData = graphDataReturningCustomers;
    }
    if (newCustomersGraphData && newCustomersGraphData.length) {
      newCustomersGraphData.sort((a, b) => (a._id > b._id ? 1 : -1));
      newCustomersData = newCustomersGraphData.map((value) =>
        value.totalAmount.toFixed(2)
      );
    }
    if (returningCustomersGraphData && returningCustomersGraphData.length) {
      returningCustomersGraphData.sort((a, b) => (a._id > b._id ? 1 : -1));
      returningCustomersData = returningCustomersGraphData.map((value) =>
        value.totalAmount ? value.totalAmount.toFixed(2) : value.totalAmount
      );
    }
    setOptions({
      grid: {
        show: false,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        padding: {
          left: 0,
          right: 0,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
        categories: newCustomersGraphData.map((value) => {
          const parts = value._id.toString().split("-");
          if (parts.length === 3) {
            return `${monthNames[parts[1]]} ${parts[2]}`;
          } else if (parts.length === 2) {
            return `${monthNames[parts[1]]} ${parts[0]}`;
          } else {
            // Handle unexpected format
            return value._id.toString();
          }
        }),
        labels: {
          style: {
            colors: "#c8cfca",
            fontSize: "10px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c8cfca",
            fontSize: "10px",
          },
          formatter: function (value) {
            return new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: selectedMerchant.currency || "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(value);
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      chart,
      plotOptions,
      legend: {
        position: "top",
        horizontalAlign: "left",
        labels: {
          colors: ["#FFFFFF", "#FFFFFF"],
        },
        itemMargin: {
          horizontal: 10,
        },
        fontFamily: "inherit",
        fontSize: "12px",
        fontWeight: 400,
        markers: {
          width: 8,
          height: 8,
          radius: 12,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
    });
    setSeries([
      { data: newCustomersData, name: "New Customers", color: "#0075FF" },
      {
        data: returningCustomersData,
        name: "Returning Customers",
        color: "#2CD9FF",
      },
    ]);
  }, [returningCustomersGraphData, newCustomersGraphData]);

  return (
    <Card>
      <VuiBox sx={{ height: "100%" }}>
        <VuiTypography
          variant="lg"
          color="white"
          fontWeight="bold"
          mb="5px"
          ml="10px"
        >
          Customers Revenue Composition
        </VuiTypography>
        <VuiBox sx={{ height: "400px" }}>
          {options && series && series.length > 0 && (
            <Chart
              options={options}
              series={series}
              type={type}
              key={type}
              width="100%"
              height="400"
            />
          )}
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default CustomersChart;
