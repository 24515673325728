export type CompareOption = "time" | "targets";

interface GetSummaryProps {
  merchantId: string;
  start: string;
  end: string;
  compareOption?: CompareOption;
}

export interface SpendSummary {
  value: number;
  compareValue: number;
  breakdown: {
    facebook: number;
    google: number;
  };
}

export interface SalesSummaryData {
  revenue: SummaryValue;
  orderCount: SummaryValue;
  aov: SummaryValue;
  totalSpend: SpendSummary;
  cost: SummaryValue;
  shipping: SummaryValue;
  contributionMargin: SummaryValue;
  mer: SummaryValue;
  pickAndPack: SummaryValue;
}

type SalesSummaryResponse = {
  data: SalesSummaryData;
};

interface GetVariantCountResponse {
  data: Array<{
    id: string;
    count: number;
    name: string;
  }>;
}

interface GetVariantRevenueResponse {
  data: Array<{
    id: string;
    revenue: number;
    name: string;
  }>;
}

type GetFunction = <T>(url: string) => Promise<T>;

export class SalesService {
  private get: GetFunction;

  constructor(getFn: GetFunction) {
    this.get = getFn;
  }

  async getSummary(params: GetSummaryProps): Promise<SalesSummaryData> {
    const { merchantId, start, end, compareOption = "time" } = params;
    const response = await this.get<SalesSummaryResponse>(
      `sales/summary?merchantId=${merchantId}&start=${start}&end=${end}&compareOption=${compareOption}`
    );
    return response.data;
  }

  async getTopVariantsByCount(
    params: GetSummaryProps
  ): Promise<GetVariantCountResponse> {
    const { merchantId, start, end } = params;
    const response = await this.get<GetVariantCountResponse>(
      `sales/variants/count?merchantId=${merchantId}&start=${start}&end=${end}&limit=10`
    );
    return response;
  }

  async getTopVariantsByRevenue(
    params: GetSummaryProps
  ): Promise<GetVariantRevenueResponse> {
    const { merchantId, start, end } = params;
    const response = await this.get<GetVariantRevenueResponse>(
      `sales/variants/revenue?merchantId=${merchantId}&start=${start}&end=${end}&limit=10`
    );
    return response;
  }
}

interface SummaryValue {
  value: number;
  compareValue: number;
}
