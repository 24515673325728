import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";
import typography from "assets/theme/base/typography";

const { transparent, primary, grey, white, dark } = colors;
const { borderRadius } = borders;
const { lg } = boxShadows;
const { size } = typography;

const dateRangePickerStyles = {
  styleOverrides: {
    root: {
      "& .MuiInputBase-root": {
        backgroundColor: transparent.main,
        border: `1px solid ${grey[600]}`,
        borderRadius: borderRadius.lg,
        color: white.main,

        "&:hover": {
          borderColor: primary.main,
        },
        "&.Mui-focused": {
          borderColor: primary.main,
          boxShadow: lg,
        },
      },
      "& .MuiInputBase-input": {
        color: white.main,
        fontSize: size.sm,
        "&::placeholder": {
          color: white.main,
          fontSize: size.sm,
        },
      },
    },
  },
  MuiDateRangePickerDay: {
    styleOverrides: {
      root: {
        borderRadius: "50%",
      },
      daySelected: {
        backgroundColor: primary.main,
        color: white.main,
        "&:hover": {
          backgroundColor: primary.main,
        },
      },
      dayInRange: {
        backgroundColor: primary.focus,
        color: white.main,
      },
      rangeIntervalDayHighlight: {
        backgroundColor: primary.focus,
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        borderRadius: "50%",
        fontSize: size.sm,
        color: dark.main,
        "&:hover": {
          backgroundColor: grey[200],
        },
      },
      today: {
        border: `1px solid ${primary.main}`,
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      root: {
        color: dark.main,
      },
      label: {
        fontSize: size.sm,
        fontWeight: 600,
      },
    },
  },
  MuiPickersPopper: {
    styleOverrides: {
      paper: {
        borderRadius: borderRadius.lg,
        boxShadow: lg,
        backgroundColor: white.main,
      },
    },
  },
};

export default dateRangePickerStyles;
